<template>
  <v-row no-gutters align='center' justify='center'>
    <v-card-text>
      <v-form>
        <v-text-field
          v-model='cpf'
          outlined
          dense
          clearable
          :label='$t("CPF")'
          hide-details
          maxlength='11'
        />
        <v-text-field
          v-model='renach'
          outlined
          dense
          clearable
          :label='$t("RENACH")'
          hide-details
          class='mt-4'
          maxlength='11'
        />
        <v-text-field
          v-model='previousRenach'
          outlined
          dense
          clearable
          :label='$t("PREVIOUS_RENACH")'
          hide-details
          class='mt-4'
          maxlength='11'
        />
        <v-checkbox
          v-model='reuseImage'
          color='secondary'
          label='Reutilizar imagem'
          hide-details
        />
      </v-form>
    </v-card-text>

    <v-card-actions class='wd-100'>
      <v-btn depressed block color='secondary' @click='importData'>
        {{$t('IMPORT_LEGACY')}}
      </v-btn>
    </v-card-actions>
  </v-row>
</template>

<script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { showError } from '../../util/notification';

  export default {
    name: 'RecordReusageForm',
    data: function () {
      return {
        cpf: undefined,
        renach: undefined,
        previousRenach: undefined,
        reuseImage: true,
      };
    },
    methods: {
      importData: async function () {
        if (!this.cpf || !this.renach || !this.previousRenach) {
          showError({ message: this.$t('YOU_MUST_INFORM_CPF_RENACH_LAST_RENACH') });
          return;
        }

        try {
          const { data } = await axios({
            url: '/record-reusage',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            params: {
              cpf: this.cpf,
              renach: this.renach,
              lastRenach: this.previousRenach,
              reuseImage: this.reuseImage,
            },
          });

          let text = '';
          const importSuccess = data.importedData && data.importedData.length > 0;

          if (importSuccess) {
            // eslint-disable-next-line no-unused-vars
            const [_, cpf, renach, __, ...name] = data.importedData[0].split(' ');
            text = `${name.join(' ')} (CPF: ${cpf} / RENACH: ${renach})`;
          } else {
            text = 'Não foi possível realizar a importação.';
          }

          await Swal.fire({
            title: this.$t(importSuccess ? 'IMPORTED_RECORD' : 'WARNING'),
            text: text,
            icon: importSuccess ? 'success' : 'warning',
            confirmButtonText: this.$t('OK'),
            confirmButtonColor: this.$vuetify.theme.defaults.light.secondary,
          });
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
